import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";

function Termsofuse() {
  return (
    <Wrapper>
       <Seo
        title="Terms of Service - bugs.work"
        description=
        "This is the privacy policy of bugs.work. It explains what personal data we collect, how we use it, and your rights regarding your data."
      />
    <div className="container mb-5">
      <div className="fnt-16">
        <h1 className="my-5 text-center fw-bold">Terms of Service</h1>
        <p>
          These Terms of Service govern your use of the website located at{' '}
          <a className="accent-color" href="https://bugs.work/">
            bugs.work
          </a>{' '}
          and any related services provided by bugs.work.
          <br></br>
          <br></br>
          By accessing{' '}
          <a className="accent-color" href="https://bugs.work/">
            bugs.work
          </a>
          , you agree to abide by these Terms of Service and to comply with all
          applicable laws and regulations. If you do not agree with these Terms
          of Service, you are prohibited from using or accessing this website or
          using any other services provided by bugs.work.
          <br></br>
          <br></br>
          We, bugs.work, reserve the right to review and amend any of these
          Terms of Service at our sole discretion. Upon doing so, we will update
          this page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.
          <br></br>
          <br></br>
          <h2>These Terms of Service were last updated on 1st March 2024.</h2>
          <strong>Using our web site</strong>
          <br></br>
          <br></br>
          When using this web site you agree to be legally bound by these terms
          and conditions as they may be modified and posted to our web site from
          time to time.
          <br></br> 
          <br></br>
          Without prejudice to the above, by using or accessing our website, you
          agree to be legally bound by these terms and conditions of use as they
          apply to your use of or access to our website.If you do not wish to be
          bound by these terms and conditions then you may not use our website.
          <br></br>
          <br></br>
          You must ensure that the personal information you provide is accurate
          and complete and that all ordering or registration details (where
          applicable) contain your correct name, address and other requested
          details. Company logos and names may be used for promotional purposes.
          For more information about how we deal with your personal information,
          please read our privacy policy on this website.
          <br></br>
          <br></br>
          <strong>Applicability of online materials</strong>
          <br></br>
          <br></br>
          We have used our reasonable endeavours to ensure that our web site
          complies with Indian law. However, we make no representations that the
          materials on our web site are appropriate or available for use in
          locations outside of India.
          <br></br>
          <br></br>
          We make no warranties, express or implied that making the Products
          available in any particular jurisdiction outside of India is permitted
          under any applicable non-Indian laws or regulations. Accordingly, if
          making the Products or any part thereof available in your jurisdiction
          or to you (by reason of nationality, residence or otherwise) is
          prohibited, those Products are not offered for sale to you. You accept
          that if you are resident outside of India, you must satisfy yourself
          that you are lawfully able to purchase the Products. We accept no
          liability, to the extent permitted by applicable law, for any costs,
          losses or damages resulting from or related to the purchase or
          attempted purchase of the Products by persons in jurisdictions outside
          of India or who are nominees of or trustees for citizens, residents or
          nationals of other countries.
          <br></br>
          <br></br>
          <strong>Copyright and monitoring</strong>
          <br></br>
          <br></br>
          The contents of our web site are protected by international copyright
          laws and other intellectual property rights. We, or other third party
          licensors, are the owner of these rights. All product and company
          names and logos mentioned in our web site are the trade marks, service
          marks or trading names of their respective owners, including us. You
          may download material from our web site for the sole purpose of using
          it as a information resource for our services. However, you may not
          modify, copy, reproduce, republish, upload, post, transmit or
          distribute, by any means or in any manner, any material or information
          on or downloaded from our web site including, but not limited to text,
          graphics, video, messages, code and/or software without our prior
          written consent, except where expressly invited to do so, for example
          in order to complete a questionnaire. We make no representations
          whatsoever about any other web sites which you may access through our
          web site or which may link to our web site. When you access any other
          web site you understand that it is independent from us and that we
          have no control over the content or availability of that web site. In
          addition, a link to any other site does not mean that we endorse or
          accept any responsibility for the content, or the use of, such a web
          site and shall not be liable for any loss or damage caused or alleged
          to be caused by or in connection with use of or reliance on any
          content, goods or services available on or through any other web or
          resource. Any concerns regarding any external link should be directed
          to its web site administrator or web master.
          <br></br>
          <br></br>
          <strong>Availability of our web site</strong>
          <br></br>
          <br></br>
          We will try to make our web site available but cannot guarantee that
          our web site will operate continuously or without interruptions or be
          error free and can accept no liability for its unavailability. You
          must not attempt to interfere with the proper working of our web site
          and, in particular, you must not attempt to circumvent security,
          tamper with, hack into, or otherwise disrupt any computer system,
          server, web site, router or any other internet connected device.
          <br></br>
          <br></br>
          We reserve the right to alter, suspend or discontinue any aspect of
          our website or the content or services available through it, including
          your access to it. Unless explicitly stated, any new features
          including new content and/or the sale of new products and/or the
          release of new software tools or resources shall be subject to these
          terms and conditions.
          <br></br>
          <br></br>
          We are committed to safeguarding the privacy of our users. We take
          your privacy very seriously and it is important to us that you can use
          and enjoy our website without having to compromise your privacy in any
          way. For more information, please see our privacy policy. bugs.work is
          provided by:
          <br></br>
          <br></br>
          Softobotics Technologies
          <br></br>
          1st floor, Prerna Towers,
          <br></br>
          Ranka Colony, Bilekahalli,
          <br></br>
          Bengaluru, Karnataka
          <br></br>
          560076
          <br></br>
          <br></br>
          If you have any questions, please get in touch.
        </p>
      </div>
    </div>
    </Wrapper>
  );
}

export default Termsofuse;
